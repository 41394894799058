import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AutoCompleteOption, OptionId, OptionLabel } from '@src/types/AutoComplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete } from '@mui/material';
import { styled } from '@mui/system';
import { style } from 'typestyle';

export type RenderTag = (id: string, onRemove: () => void) => JSX.Element;

export interface AutoCompleteProps {
  loading: boolean;
  options: AutoCompleteOption[] | any;
  selected?: string[];
  title: string;
  onInputChange?: (val: string) => void;
  onAdd: (val: OptionId) => void;
  onRemove: (val: OptionId) => void;
  renderTag: RenderTag;
}

const StyledInput = styled(TextField)(({ theme }) => ({
  borderColor: 'white',
  '&::placeholder': {
    fontStyle: 'italic',
  },
}));

export function AutoComplete({ loading, options, selected, title, onAdd, onRemove, renderTag }: AutoCompleteProps) {
  const defaultTags = options.filter((op: { id: OptionId; label: OptionLabel }) => selected?.find((s) => s === op.id));
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);
  useEffect(() => {
    const newOptions = options.filter(
      (op: { id: OptionId; label: OptionLabel }) => !selected?.find((s) => s === op.id) && new RegExp(`${inputValue}`, 'ig').test(`${op.label}`)
    );
    setFilteredOptions(newOptions);
  }, [inputValue, selected]);

  return (
    <Autocomplete
      className={autoCompleteClass}
      hidden={!options.length && loading}
      multiple
      options={filteredOptions}
      disableClearable
      popupIcon={<KeyboardArrowDownIcon />}
      getOptionLabel={(option) => option.label}
      value={selected ? defaultTags : undefined} // Controlled when selected is passed
      defaultValue={selected ? undefined : defaultTags} // Uncontrolled fallback
      filterSelectedOptions
      renderTags={(values, getTagProps) =>
        values.map((option, index) =>
          renderTag(option.id, () => {
            const tagProps = { ...getTagProps({ index }) };
            tagProps.onDelete(index);
            onRemove(option.id);
          })
        )
      }
      onInputChange={(event, value) => setInputValue(value)}
      onChange={(e, values, reason) => {
        if (reason === 'selectOption') {
          const optionId = [...values]?.pop()?.id;
          if (optionId) {
            onAdd(optionId);
          }
        }
      }}
      renderInput={(params) => <StyledInput {...params} placeholder={title} />}
    />
  );
}

const autoCompleteClass = style({
  marginTop: '10px',
});
